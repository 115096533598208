import React, { useEffect, useState } from "react";
import { useLocalStorageState } from "ahooks";
import Cookies from "js-cookie";
import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";

interface userAuthProps {
  user?: any;
  hasLogin: boolean;
  pending: boolean;
  reawake: () => Promise<any>;
  // refresh: () => Promise<void>;
  setUser: (user: any) => void;
  handleFetchUser: () => Promise<void>;
}

const userLocalKey = "user";

export const getProvider = () => Cookies.get("Provider");

let fetchPromise = null;

const whiteList = ["/share/widget"];
const useAuth = (): userAuthProps => {
  const [pending, setPending] = useState(true);

  const [user, setUser] = useLocalStorageState<any>(userLocalKey, {
    defaultValue: {},
  });

  const fetchUser = async () => {
    // SmartCoderUserInfo从服务端注入
    const SmartCoderUserInfo = (window as any).SmartCoderUserInfo
    if (SmartCoderUserInfo?.id) {
      setUser(SmartCoderUserInfo);
      return SmartCoderUserInfo;
    }
    setUser({});
    return undefined;
  };

  const handleFetchUser = async () => {
    try {
      setPending(true);
      if (!fetchPromise) {
        fetchPromise = fetchUser;
      }
      const user: any = await fetchPromise();
      setUser(user);
      setPending(false);
    } catch (error) {
      console.error("fetch user error", error);
    }
    if (ExecutionEnvironment.canUseDOM) {
      window.name = "smartcoder.studio";
    }
  };

  // useEffect(() => {
  //   if (ExecutionEnvironment.canUseDOM) {
  //     if (whiteList.some((item) => window.location.pathname.startsWith(item))) {
  //       return;
  //     }
  //     if (!user?.id || !window?.name) {
  //       handleFetchUser();
  //     } else {
  //       setPending(false);
  //     }
  //   }
  // }, []);

  return {
    user,
    pending,
    setUser,
    reawake: fetchUser,
    handleFetchUser,
    hasLogin: user?.name,
  };
};

export { useAuth };
