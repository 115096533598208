import { Code } from "./icon-code";
import { Json } from "./icon-json";
import { Markdown } from "./icon-markdown";
import { PDF } from "./icon-pdf";
import { Text } from "./icon-txt";

export const fileIcons = {
  Text,
  PDF,
  Json,
  Code,
  Markdown
};
