import { LucideProps } from "lucide-react";

export function IconResetContext({ ...props }: LucideProps) {
  return (
    <svg viewBox="0 0 1024 1024" fill="currentColor" {...props}>
      <path d="M847.56308 444.137403 175.928337 444.137403c-37.036521 0-67.163679-30.127158-67.163679-67.162656L108.764658 108.321054c0-12.353352 10.014074-22.387893 22.387893-22.387893s22.387893 10.034541 22.387893 22.387893l0 268.653693c0 12.353352 10.014074 22.387893 22.387893 22.387893l671.63372 0c12.328793 0 22.387893-10.034541 22.387893-22.387893L869.94995 108.321054c0-12.353352 10.057053-22.387893 22.387893-22.387893 12.329816 0 22.38687 10.034541 22.38687 22.387893l0 268.653693C914.725736 414.010245 884.598578 444.137403 847.56308 444.137403z"></path>
      <path d="M131.153574 533.688975 86.377788 533.688975c-12.373819 0-22.387893-10.05603-22.387893-22.387893 0-12.352329 10.014074-22.38687 22.387893-22.38687l44.775786 0c12.373819 0 22.387893 10.034541 22.387893 22.38687C153.540444 523.632945 143.527392 533.688975 131.153574 533.688975z"></path>
      <path d="M265.479909 533.688975l-44.775786 0c-12.373819 0-22.387893-10.05603-22.387893-22.387893 0-12.352329 10.014074-22.38687 22.387893-22.38687l44.775786 0c12.375865 0 22.38687 10.034541 22.38687 22.38687C287.866778 523.632945 277.85475 533.688975 265.479909 533.688975z"></path>
      <path d="M399.807267 533.688975l-44.775786 0c-12.373819 0-22.38687-10.05603-22.38687-22.387893 0-12.352329 10.014074-22.38687 22.38687-22.38687l44.775786 0c12.373819 0 22.38687 10.034541 22.38687 22.38687C422.194136 523.632945 412.181085 533.688975 399.807267 533.688975z"></path>
      <path d="M534.133601 533.688975l-44.775786 0c-12.372795 0-22.387893-10.05603-22.387893-22.387893 0-12.352329 10.015098-22.38687 22.387893-22.38687l44.775786 0c12.331863 0 22.387893 10.034541 22.387893 22.38687C556.521494 523.632945 546.465464 533.688975 534.133601 533.688975z"></path>
      <path d="M668.459936 533.688975l-44.775786 0c-12.33084 0-22.38687-10.05603-22.38687-22.387893 0-12.352329 10.05603-22.38687 22.38687-22.38687l44.775786 0c12.329816 0 22.388916 10.034541 22.388916 22.38687C690.848852 523.632945 680.789753 533.688975 668.459936 533.688975z"></path>
      <path d="M802.787294 533.688975l-44.776809 0c-12.328793 0-22.385846-10.05603-22.385846-22.387893 0-12.352329 10.057053-22.38687 22.385846-22.38687l44.776809 0c12.329816 0 22.38687 10.034541 22.38687 22.38687C825.174164 523.632945 815.117111 533.688975 802.787294 533.688975z"></path>
      <path d="M937.114652 533.688975l-44.776809 0c-12.33084 0-22.387893-10.05603-22.387893-22.387893 0-12.352329 10.057053-22.38687 22.387893-22.38687l44.776809 0c12.328793 0 22.38687 10.034541 22.38687 22.38687C959.501522 523.632945 949.443445 533.688975 937.114652 533.688975z"></path>
      <path d="M892.338866 936.669002c-12.33084 0-22.387893-10.05603-22.387893-22.38687L869.950973 645.627417c0-12.329816-10.0591-22.385846-22.387893-22.385846L175.928337 623.24157c-12.373819 0-22.387893 10.05603-22.387893 22.385846l0 268.654716c0 12.33084-10.014074 22.38687-22.387893 22.38687s-22.387893-10.05603-22.387893-22.38687L108.764658 645.627417c0-37.034475 30.127158-67.162656 67.163679-67.162656l671.63372 0c37.035498 0 67.162656 30.127158 67.162656 67.162656l0 268.654716C914.725736 926.612972 904.667659 936.669002 892.338866 936.669002z"></path>
    </svg>
  );
}
