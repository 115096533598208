import { Tooltip } from "antd";
import React from "react";
import Discord from "./discord";
import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";
export default function ({ className }: any) {
    let discordUrl = 'https://discord.gg/KyjgY7g8'
    if (ExecutionEnvironment.canUseDOM) {
        discordUrl = window.DISCORD_INVITE
    }
    return <Tooltip title="Join us in Discord">
        <a href={discordUrl} target="_blank" className={className}>
            <Discord className="w-6 cursor-pointer" />
        </a>
    </Tooltip>
}