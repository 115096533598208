import { LucideProps } from "lucide-react";

export function RightArrow({ ...props }: LucideProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M2.66669 8H13.3334M13.3334 8L9.33335 4M13.3334 8L9.33335 12"
        stroke={props.color || "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
