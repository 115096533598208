import { LucideProps } from "lucide-react";

export function IconLogoBlack({ ...props }: LucideProps) {
  return (
    <svg  {...props} width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="8" fill="url(#paint0_linear_9186_14146)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.3736 22.177C32.0542 27.4842 26.5243 31.4667 19.9029 31.4667C13.3115 31.4667 7.80175 27.5202 6.45045 22.2489C6.26009 22.3154 6.05548 22.3516 5.84242 22.3516C4.82488 22.3516 4 21.5267 4 20.5091V19.1516C4 18.134 4.82488 17.3091 5.84242 17.3091C6.03908 17.3091 6.22853 17.34 6.40623 17.397C7.67735 12.0362 13.237 8 19.9029 8H19.903C23.7672 8 27.2598 9.35634 29.7605 11.5409L29.7605 11.5409C31.5917 13.1406 32.8912 15.1843 33.4155 17.4647C33.6425 17.3647 33.8935 17.3091 34.1575 17.3091C35.175 17.3091 35.9999 18.134 35.9999 19.1516V20.5091C35.9999 21.5267 35.175 22.3516 34.1575 22.3516C33.8771 22.3516 33.6114 22.289 33.3736 22.177ZM8.84839 19.7333C8.84839 17.1329 10.3013 14.5841 12.7485 12.8632C14.8829 13.8375 17.4007 14.4 20.0969 14.4C22.6932 14.4 25.1239 13.8784 27.2064 12.9698C29.5634 14.6884 30.9575 17.1861 30.9575 19.7333C30.9575 19.8695 30.9535 20.0055 30.9456 20.1413C30.3473 19.7278 29.7195 19.3441 29.0633 18.9934C27.9815 18.4151 26.8682 17.951 25.7376 17.597C25.2265 17.437 24.6825 17.7216 24.5225 18.2326C24.3625 18.7437 24.6471 19.2878 25.1581 19.4478C26.1736 19.7657 27.1748 20.183 28.1491 20.7038C28.9911 21.1538 29.7799 21.6634 30.5133 22.2244C30.3921 22.5608 30.247 22.8916 30.0791 23.2149C28.0782 26.0374 24.2279 27.9481 19.8066 27.9481C15.8817 27.9481 12.4068 26.4424 10.2762 24.1297C9.89358 23.5723 9.5823 22.9841 9.34918 22.3766C10.9077 21.1464 12.6588 20.2042 14.5152 19.5772C15.0225 19.4058 15.2949 18.8555 15.1235 18.3481C14.9521 17.8407 14.4019 17.5684 13.8945 17.7398C12.1134 18.3414 10.4183 19.2043 8.87211 20.3098C8.85636 20.1182 8.84839 19.9259 8.84839 19.7333ZM23.0612 17.9234C23.0005 18.4555 22.5199 18.8376 21.9878 18.7769C20.5423 18.6121 19.092 18.6336 17.6712 18.8313C17.1408 18.9051 16.6509 18.535 16.5771 18.0045C16.5033 17.4741 16.8735 16.9842 17.4039 16.9104C18.9841 16.6905 20.598 16.6665 22.2076 16.85C22.7397 16.9107 23.1218 17.3913 23.0612 17.9234Z" fill="url(#paint1_linear_9186_14146)" />
      <path d="M29.0633 18.9926C27.9815 18.4144 26.8682 17.9503 25.7376 17.5963C25.2265 17.4362 24.6825 17.7208 24.5225 18.2319C24.3625 18.743 24.6471 19.287 25.1581 19.4471C26.1736 19.765 27.1748 20.1823 28.1491 20.7031C29.1234 21.2238 30.0266 21.8244 30.8552 22.492C31.2722 22.8281 31.8826 22.7624 32.2186 22.3454C32.5547 21.9284 32.489 21.318 32.072 20.9819C31.1495 20.2386 30.1451 19.5709 29.0633 18.9926Z" fill="url(#paint2_linear_9186_14146)" />
      <path d="M21.9878 18.7762C22.5199 18.8369 23.0005 18.4548 23.0612 17.9227C23.1218 17.3906 22.7397 16.91 22.2076 16.8493C20.598 16.6658 18.9841 16.6898 17.4039 16.9097C16.8735 16.9835 16.5033 17.4734 16.5771 18.0038C16.6509 18.5342 17.1408 18.9044 17.6712 18.8306C19.092 18.6329 20.5423 18.6114 21.9878 18.7762Z" fill="url(#paint3_linear_9186_14146)" />
      <path d="M14.5152 19.5764C15.0225 19.4051 15.2949 18.8548 15.1235 18.3474C14.9521 17.84 14.4019 17.5677 13.8945 17.7391C11.627 18.505 9.49898 19.6944 7.63994 21.2687C7.23124 21.6147 7.18049 22.2266 7.52658 22.6353C7.87267 23.044 8.48455 23.0948 8.89325 22.7487C10.5629 21.3348 12.4755 20.2654 14.5152 19.5764Z" fill="url(#paint4_linear_9186_14146)" />
      <defs>
        <linearGradient id="paint0_linear_9186_14146" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7181FF" />
          <stop offset="1" stopColor="#C35EFF" />
        </linearGradient>
        <linearGradient id="paint1_linear_9186_14146" x1="20" y1="8" x2="20" y2="31.4667" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
        </linearGradient>
        <linearGradient id="paint2_linear_9186_14146" x1="7.29688" y1="19.8525" x2="32.4333" y2="19.8525" gradientUnits="userSpaceOnUse">
          <stop offset="0.0686508" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint3_linear_9186_14146" x1="7.29688" y1="19.8525" x2="32.4333" y2="19.8525" gradientUnits="userSpaceOnUse">
          <stop offset="0.0686508" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint4_linear_9186_14146" x1="7.29688" y1="19.8525" x2="32.4333" y2="19.8525" gradientUnits="userSpaceOnUse">
          <stop offset="0.0686508" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}