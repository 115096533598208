import { Button } from "antd";
import { useHistory } from "@docusaurus/router";
import { Icons } from "@site/src/components/icons";
import { UserCenter } from "@site/src/components/user-center";
import { useContext, useEffect, useState } from "react";
import UserContext from "../user-context";
import DiscordButton from "./discord-button";
import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";

const UserInfo = () => {
  const navigateToLogin = () => {
    if (ExecutionEnvironment.canUseDOM) {
      window.location.href = `${window.location.protocol}//accounts.${window.SmartCoderDomain}/login`
    }
  };
  let user = {}
  if (ExecutionEnvironment.canUseDOM) {
    user = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  }

  const [currentUser, setCurrentUser] = useState(user as any);

  return (
    <div className="grow h-full flex items-center justify-center gap-4 font-semibold">
      {!currentUser?.id && (
        <div className="grow h-full flex items-center justify-center gap-4">
          <DiscordButton />
          <Button
            onClick={navigateToLogin}
            className="text-primary bg-[#F9F5FF] border-0"
          >
            {/* <Icons.Twitter className="w-3" /> */}
            <span>Sign in</span>
          </Button>
          <Button
            onClick={navigateToLogin}
            className="bg-primary text-white flex items-center "
          >
            <span>Join free</span>
            <Icons.RightArrow className="ml-2 hover:stroke-primary" />
          </Button>
        </div>
      )}
      {currentUser?.id && (
        <div>
          <UserCenter user={currentUser}></UserCenter>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
