import { LayoutGrid } from "lucide-react";

import { IconChartBreakout } from "./chart-breakout-circle";
import { IconChevronDown } from "./chevron-down";
import { IconDataflow } from "./dataflow";
import { fileIcons } from "./file";
import { IconFileDoc } from "./file-doc";
import { IconAi } from "./icon-ai";
import { IconBot } from "./icon-bot";
import { IconGithub } from "./icon-github";
import { IconGoogle } from "./icon-google";
import { IconHomeArrow } from "./icon-home-arrow";
import { IconIconFilled } from "./icon-info-filled";
import { IconLinkBreak } from "./icon-link-break";
import { IconListRestart } from "./icon-list-restart";
import { IconQuestion } from "./icon-qustion";
import { IconResetContext } from "./icon-reset-context";
import { IconTwitter } from "./icon-twitter";
import { IconVariable } from "./icon-variable";
import { IconWindow } from "./icon-window";
import { IconWorkflow } from "./icon-workflow";
import { IconLogoBlack } from "./logo-black";
import * as lucideIcons from "./lucideIcons";
import { IconMinus } from "./minus";
import { IconPieChart } from "./pie-chart";
import { IconPlus } from "./plus";
import { IconRepeat } from "./repeat";
import { RightArrow } from "./right-arrow";
import { user } from "./user";

export const Icons = {
  ...fileIcons,
  ...lucideIcons,
  IconHomeArrow: IconHomeArrow,
  IconChevronDown: IconChevronDown,
  IconMinus: IconMinus,
  IconPlus: IconPlus,
  Logo: lucideIcons.Command,
  BotWindow: IconWindow,
  Variable: IconVariable,
  ResetContext: IconResetContext,
  GainNodeGitHub: IconGithub,
  Google: IconGoogle,
  Bot: IconBot,
  Ai: IconAi,
  ListRestart: IconListRestart,
  LinkBreak: IconLinkBreak,
  InfoFilled: IconIconFilled,
  Twitter: IconTwitter,
  Workflow: IconWorkflow,
  Question: IconQuestion,
  RightArrow: RightArrow,
  User: user,
  Dataflow: IconDataflow,
  FileDoc: IconFileDoc,
  PieChart: IconPieChart,
  ChartBreakout: IconChartBreakout,
  Repeat: IconRepeat,
  IconLogoBlack: IconLogoBlack,
  LayoutGrid: LayoutGrid
};
