import { ClassValue, clsx } from "clsx";
import copy from "copy-to-clipboard";
import { twMerge } from "tailwind-merge";
import botIcon from "@site/src/assets/default-logo.png";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function formatArray(arr?: any) {
  return arr ? (Array.isArray(arr) ? arr : [arr]) : [];
}

/**
 * copy to clipboard
 * @param text
 */

export const copyToClipboard = (text?: string) => copy(text || "");

/**
 * is IOS ?
 * @returns
 */
export const isIOSPlatform = () => {
  return /macintosh|mac os x/i.test(navigator.userAgent);
};

/**
 * get uuid
 * @param length  length
 * @param join join char
 * @returns
 */
export function getUUID(length = 32, join = "") {
  const withLine = true; //带不带横线
  const len = length; //长度为36
  let radix = 16; //16进制
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  // eslint-disable-next-line prefer-const
  let uuid = [],
    i;
  radix = radix || chars.length;
  if (withLine) {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = join;
    uuid[14] = "4";
    for (i = 0; i < len; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  } else {
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | (Math.random() * radix)];
    }
  }
  return uuid.join("");
}

export function formatFileSize(size?: number) {
  if (!size) return "-";
  if (size > 1024 * 1024) return `${(size / 1024 / 1024).toFixed(1)}MB`;
  if (size > 1024) return `${(size / 1024).toFixed(1)}KB`;
  return `${size}B`;
}

export const onErrorBotLogo = ({
  currentTarget,
}: {
  currentTarget: EventTarget & HTMLImageElement;
}) => {
  currentTarget.onerror = null;
  currentTarget.src = botIcon;
};
