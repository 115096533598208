import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ConfigProvider } from "antd";
import ErrorBoundary from "@docusaurus/ErrorBoundary";
import {
  PageMetadata,
  SkipToContentFallbackId,
  ThemeClassNames,
} from "@docusaurus/theme-common";
import { useKeyboardNavigation } from "@docusaurus/theme-common/internal";
import SkipToContent from "@theme/SkipToContent";
import AnnouncementBar from "@theme/AnnouncementBar";
import Navbar from "@theme/Navbar";
import Footer from "@theme/Footer";
import LayoutProvider from "@theme/Layout/Provider";
import ErrorPageContent from "@theme/ErrorPageContent";
import type { Props } from "@theme/Layout";
import styles from "./styles.module.css";
import { useAuth } from "@site/src/hooks/use-auth";
import UserContext from "@site/src/components/user-context";

export default function Layout(props: Props): JSX.Element {
  const {
    children,
    noFooter,
    wrapperClassName,
    // Not really layout-related, but kept for convenience/retro-compatibility
    title,
    description,
  } = props;
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { user, handleFetchUser, setUser } = useAuth();

  useEffect(() => {
    if (isFirstRender || !user?.id) {
      handleFetchUser();
    }
    setIsFirstRender(false);
  }, []);

  useKeyboardNavigation();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#BA3EF0",
          lineWidthFocus: 0,
          // colorPrimaryBorderHover: "red",
          // colorPrimaryActive: "green",
          // colorPrimaryBorder: "blue",
          // colorPrimaryHover: "purple",
          colorBorder: "hsl(var(--border))",
        },
        components: {
          Button: {
            colorLinkActive: "var(--primary)",
            colorLinkHover: "var(--primary)",
            algorithm: true, // 启用算法
          },
        },
      }}
    >
      <UserContext.Provider value={{ user, setUser }}>
        <LayoutProvider>
          <PageMetadata title={title} description={description} />
          <SkipToContent />
          <AnnouncementBar />
          <Navbar />
          <div
            id={SkipToContentFallbackId}
            className={clsx(
              ThemeClassNames.wrapper.main,
              styles.mainWrapper,
              wrapperClassName
            )}
          >
            <ErrorBoundary
              fallback={(params) => <ErrorPageContent {...params} />}
            >
              {children}
            </ErrorBoundary>
          </div>

          {!noFooter && <Footer />}
        </LayoutProvider>
      </UserContext.Provider>
    </ConfigProvider>
  );
}
