import { UserLogout } from "@site/src/services/auth";
// import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button, Popover, Dropdown } from "antd";
import { useHistory } from "@docusaurus/router";

import { LinkRedirect } from "@site/src/config/link";

import { Icons } from "./icons";
import Menu from "./menu";
import { UserAvatar } from "./user-avatar";
import { useAuth } from "../hooks/use-auth";
import { useContext } from "react";
import UserContext from "./user-context";
import DiscordButton from "./header/discord-button";
import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";

interface UserCenterProps {
  user: any;
}

export function UserCenter(props: UserCenterProps) {
  // const { user, setUser } = useAuth();
  const { user, setUser } = useContext(UserContext);
  // const { user } = props;
  const navigate = useHistory();
  // const handleSetAccount = () => {
  //   navigate.push(LinkRedirect.account);
  // };
  const handleLogout = () => {
    if (ExecutionEnvironment.canUseDOM) {
      window.location.href = `${window.location.protocol}//accounts.${window?.SmartCoderDomain}/auth/logout`
    }
  };

  const menu = {
    items: [
      {
        label: (
          <div className="flex items-center" onClick={handleLogout}>
            <Icons.LogOut className="w-4 mx-1" />
            <span>Sign out</span>
          </div>
        ),
        key: 1,
      },
    ],
  };

  return (
    <div className="flex items-center justify-end flex-row">
      {/* <Popover
        placement="bottomLeft"
        content={<Menu />}
        arrow={false}
        overlayInnerStyle={{ padding: 0 }}
      >
        <Button
          size="small"
          className="flex items-center border-gray-300 padding-x-0 border-0 nav-bg shadow-none"
        >
          <Icons.LayoutGrid width="20" height="20" />
        </Button>
      </Popover> */}
      <Button
        // type="link"
        className="text-[#475467] mr-4"
        href={`${window.location.protocol}//workspace.${window?.SmartCoderDomain}`}
      >
        Workspace
      </Button>
      <DiscordButton className="mr-4" />
      <Dropdown
        className={`header-right-menu`}
        menu={menu}
        placement="bottomRight"
        arrow={false}
      >
        <Button type="text" className="h-8 px-0 flex items-center gap-1">
          <UserAvatar className="h-6 w-6" value={user} />
        </Button>
      </Dropdown>
      {/* <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button type="text" className="h-8 px-0 flex items-center gap-1">
            <UserAvatar className="h-6 w-6" value={user} />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content align="center" className="DropdownMenuContent">
            <DropdownMenu.Item
              className="flex gap-1 cursor-pointer"
              onClick={handleLogout}
            >
              <Icons.LogOut className="w-4 mx-1" />
              <span>Sign out</span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root> */}
    </div>
  );
}
