import { LucideProps } from "lucide-react";

export function IconQuestion({ ...props }: LucideProps) {
  return (
    <svg viewBox="0 0 1024 1024" {...props} fill="currentColor">
      <path d="M878.933333 1024c-8.533333 0-17.066667 0-21.333333-4.266667l-179.2-110.933333c-21.333333 8.533333-42.666667 12.8-59.733333 17.066667-34.133333 8.533333-72.533333 12.8-106.666667 12.8-260.266667 0-469.333333-209.066667-469.333333-469.333334S251.733333 0 512 0s469.333333 209.066667 469.333333 469.333333c0 55.466667-8.533333 106.666667-25.6 157.866667-8.533333 25.6-21.333333 55.466667-38.4 81.066667-12.8 21.333333-38.4 25.6-59.733333 17.066666-21.333333-12.8-25.6-38.4-17.066667-59.733333 12.8-21.333333 21.333333-42.666667 29.866667-64 12.8-42.666667 21.333333-85.333333 21.333333-128 0-213.333333-170.666667-384-384-384s-384 170.666667-384 384 170.666667 384 384 384c29.866667 0 59.733333-4.266667 85.333334-8.533333 21.333333-4.266667 46.933333-12.8 68.266666-21.333334 12.8-4.266667 25.6-4.266667 38.4 4.266667l132.266667 81.066667v-72.533334c0-25.6 17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666666 42.666666V981.333333c0 17.066667-8.533333 29.866667-21.333333 38.4 0 4.266667-8.533333 4.266667-17.066667 4.266667z"></path>
      <path d="M512 618.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667V512c0-25.6 17.066667-42.666667 42.666667-42.666667 46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333c0 25.6-17.066667 42.666667-42.666667 42.666667s-42.666667-17.066667-42.666667-42.666667c0-93.866667 76.8-170.666667 170.666667-170.666667s170.666667 76.8 170.666667 170.666667c0 81.066667-55.466667 145.066667-128 166.4v25.6c0 25.6-17.066667 42.666667-42.666667 42.666667z"></path>
      <path d="M512 682.666667m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"></path>
    </svg>
  );
}
