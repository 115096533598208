import { LucideProps } from "lucide-react";

export function IconLinkBreak({ ...props }: LucideProps) {
  return (
    <svg viewBox="0 0 1024 1024" width="200" height="200" {...props}>
      <path
        d="M573.27 630.11c-3.59-2.51-8.61-1.63-11.13 1.96l-94.26 134.61c-43.64 62.32-132.07 83.71-200.9 35.42-68.93-48.26-79.06-138.58-35.42-200.9l94.26-134.61c2.51-3.59 1.63-8.61-1.96-11.13l-46.11-32.28c-3.59-2.51-8.61-1.63-11.13 1.96l-94.26 134.61c-68.62 98.01-44.85 232.83 53.14 301.35s232.83 44.85 301.35-53.14l94.26-134.61c2.51-3.59 1.63-8.61-1.96-11.13l-45.88-32.11z m287.94-404.6c-68.62-98.01-203.44-121.78-301.35-53.14l-134.71 94.24c-3.59 2.51-4.48 7.54-1.96 11.13l32.2 45.99c2.51 3.59 7.54 4.48 11.13 1.96l134.61-94.26c62.32-43.64 152.74-33.49 200.9 35.42 48.26 68.93 26.9 157.26-35.42 200.9L632 562.02c-3.59 2.51-4.48 7.54-1.96 11.13l32.28 46.11c2.51 3.59 7.54 4.48 11.13 1.96l134.61-94.26c97.91-68.64 121.68-203.46 53.15-301.45z m-572.74 95.6c1.13-4.23-1.42-8.65-5.65-9.79l-162.5-43.61c-4.23-1.13-8.65 1.42-9.79 5.65l-14.49 54.09c-1.13 4.23 1.42 8.65 5.65 9.79l162.45 43.53c4.23 1.13 8.65-1.42 9.79-5.65l14.54-54.01z m96.59-51.09c4.23-1.13 6.78-5.55 5.65-9.79L347.24 97.7c-1.13-4.23-5.55-6.78-9.79-5.65l-54.09 14.49c-4.23 1.13-6.78 5.55-5.65 9.79l43.53 162.45c1.13 4.23 5.55 6.78 9.79 5.65l54.03-14.41z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
