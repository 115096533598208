import { LucideProps } from "lucide-react";

export function IconChevronDown({ ...props }: LucideProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="#D0D5DD" xmlns="http://www.w3.org/2000/svg">
      <g id="minus-circle">
      <path id="Icon" d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );      
}
